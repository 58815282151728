//##############################################################################
// Initializer
//##############################################################################

import Turbolinks from 'turbolinks'
import Lozad      from 'lozad'
import MoveTo     from 'moveto'

/**
 *  初期化クラス
 *  @version 2018/06/10
 */
export default new class Initializer {

  /**
   *  コンストラクタ
   *  @version 2018/06/10
   */
  constructor() {

    // turbolinkの開始
    Turbolinks.start();

    // turbolinksのパッチ
    this.setTurbolinksMonkeyPatch();

    // 画像の遅延読み込み
    window.lazy_load = Lozad('.lazy', {
      load: e => { this.lazyLoaded(e) },
    });
  }

  /**
   *  lazyload時の処理
   *  @version 2018/06/10
   */
   lazyLoaded = e => {

    e.src = e.dataset.src;
    e.removeAttribute('data-src');
    e.onload = () => {
      e.classList.add('lazyloaded');
      e.classList.remove('lazy');
    }
  }

  /**
   *  webfontをダウンロード
   *  @version 2018/06/10
   */
  loadWebfont = () => {

    let font_element = document.getElementById('js-webfont');
    if (font_element) font_element.rel = 'stylesheet';
  }

  /**
   *  ページ内リンクをスムーズスクロールへ
   *  @version 2018/06/10
   */
  smoothScroll = () => {

    this.moveTo = new MoveTo({
      tolerance: 0,
      duration: 500,
    });
    document.querySelectorAll('a[href^="#"]').forEach(trigger => {

      this.moveTo.registerTrigger(trigger);
    });
  }

  /**
   *  ヘッダーをsticky化
   *  @version 2018/06/10
   */
  stickyStart = () => {

    const sticky_header = document.getElementById('js-sticky-header');

    window.addEventListener('scroll', () => {

      window.current_scroll_position = window.scrollY;

      if(window.current_scroll_position > 100 && window.current_scroll_position > window.last_scroll_position) {
        sticky_header.classList.add('unsticky');
      } else {
        sticky_header.classList.remove('unsticky');
      }
      window.last_scroll_position = window.current_scroll_position;
    });
  }

  /**
   *  lazyloadを開始
   *  @version 2018/06/10
   */
  lazy = () => {

    // 画像の遅延読み込み(loading属性に対応するブラウザの場合はlozadを実行しない)
    if ('loading' in HTMLImageElement.prototype) {

      document.querySelectorAll('.lazy').forEach(e => this.lazyLoaded(e));
    } else {

      window.lazy_load.observe();
    }
  }

  /**
   *  navigationメニューをactive化
   *  @version 2018/06/10
   */
  navigation = () => {

    this.activationNavigation();

    // 画面遷移時にnavigationメニューをactiveにする
    document.addEventListener('turbolinks:load', this.activationNavigation);
  }

  /**
   *  navigationメニューをactive化
   *  @version 2018/06/10
   */
  activationNavigation = () => {

    const breadcrumb_root = document.getElementById('js-breadcrumb-root');

    document.querySelectorAll('.l-navigation__link').forEach(element => {

      // TOPタブのアクティブ化
      if (!breadcrumb_root && element.getAttribute('href') == '/') {

        element.classList.add('is-active');

      // TOPページ以外のタブのアクティブ化
      } else if (breadcrumb_root && breadcrumb_root.textContent == element.textContent) {

        element.classList.add('is-active');

        // 対称の位置まで横スクロールする
        document.querySelector('.l-navigation__list').scrollLeft = element.getBoundingClientRect().left - (screen.width / 2) + (element.clientWidth / 2);

      // 非アクティブ化
      } else {

        element.classList.remove('is-active');
      }
    });
  }

  /**
   *  左drawerを開始
   *  @version 2018/06/10
   */
  drawerLeft = () => {

    document.querySelectorAll('.js-drawer-left-open').forEach(trigger => trigger.onclick = () => {

      document.body.dataset.drawerLeftOpen = document.body.dataset.drawerLeftOpen == 'true'? 'false' : 'true';

      // アクセシビリティ
      document.querySelectorAll('.js-drawer-left-open').forEach(trigger => {

        trigger.setAttribute('aria-pressed', document.body.dataset.drawerLeftOpen);
      });
    });
  }

  /**
   *  右drawerを開始
   *  @version 2018/06/10
   */
  drawerRight = () => {

    document.querySelectorAll('.js-drawer-right-open').forEach(trigger => trigger.onclick = () => {

      document.body.dataset.drawerRightOpen = document.body.dataset.drawerRightOpen == 'true' ? 'false' : 'true';

      // アクセシビリティ
      document.querySelectorAll('.js-drawer-right-open').forEach(trigger => {

        trigger.setAttribute('aria-pressed', document.body.dataset.drawerRightOpen);
      });
    });
  }

  /**
   *  google analytics
   *  @version 2018/06/10
   */
  analytics = () => {

    // 本番環境かつ、analyticsIDが登録されている場合
    if (gon.environment == 'production' && gon.analytics_account) {

      (function(i,s,o,g,r,a,m){i['GoogleAnalyticsObject']=r;i[r]=i[r]||function(){
      (i[r].q=i[r].q||[]).push(arguments)},i[r].l=1*new Date();a=s.createElement(o),
      m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m)
      })(window, document, 'script', 'https://www.google-analytics.com/analytics.js','ga');

      ga('create', gon.analytics_account, 'auto');
      ga('send', 'pageview');

      // ページ遷移時
      document.addEventListener('turbolinks:load', () => {

        ga('set', 'location', location.href.split('#')[0]);
        ga('send', 'pageview');
      });
    }
  }

  /**
   *  turbolinksへのモンキーパッチ
   *  @version 2018/06/10
   */
  setTurbolinksMonkeyPatch = () => {

    // リクエストheaderを追加
    document.addEventListener('turbolinks:request-start', event => {
      event.data.xhr.setRequestHeader('X-Requested-With', 'XMLHttpRequest');
    });

    window.Turbolinks.HttpRequest.prototype.requestLoaded = function() {

      return this.endRequest(function() {

        const code = this.xhr.status;
        if (200 <= code && code < 300 || code === 403 || code === 404 || code === 500) {

          this.delegate.requestCompletedWithResponse(
            this.xhr.responseText,
            this.xhr.getResponseHeader('Turbolinks-Location')
          );
          window.scrollTo(0, 0);
        } else {

          this.failed = true;
          this.delegate.requestFailedWithStatusCode(code, this.xhr.responseText);
        }
      });
    };
  }

  /**
   *  turbolinkのdebug
   *  @version 2018/06/10
   */
  turbolinksDebug = () => {

    // linkクリック時
    document.addEventListener('turbolinks:click', e => {
      console.log('turbolinks:click', e);
      console.log(Turbolinks.controller);
    });
    document.addEventListener('turbolinks:before-visit', e => {
      console.log('turbolinks:before-visit', e);
      console.log(Turbolinks.controller);
    });
    // ページ遷移前 以前: page:before-unload
    document.addEventListener('turbolinks:request-start', e => {
      console.log('turbolinks:request-start', e);
      console.log(Turbolinks.controller);
    });
    document.addEventListener('turbolinks:visit', e => {
      console.log('turbolinks:visit', e);
      console.log(Turbolinks.controller);
    });
    document.addEventListener('turbolinks:request-end', e => {
      console.log('turbolinks:request-end', e);
      console.log(Turbolinks.controller);
    });
    document.addEventListener('turbolinks:before-cache', e => {
      console.log('turbolinks:before-cache', e);
      console.log(Turbolinks.controller);
    });
    document.addEventListener('turbolinks:before-render', e => {
      console.log('turbolinks:before-render', e);
      console.log(Turbolinks.controller);
    });
    // ページ切り替え時（初回ページは対象外） 以前: page:before-unload
    document.addEventListener('turbolinks:render', e => {
      console.log('turbolinks:render', e);
      console.log(Turbolinks.controller);
    });
    // ページ切り替え時（初回ページも対象） 以前: ready page:load
    document.addEventListener('turbolinks:load', e => {
      console.log('turbolinks:load', e);
      console.log(Turbolinks.controller);
    });
  }
}
