import React   from 'react'
import Style   from './style.sass'

/**
 *  @version 2018/06/10
 */
export default class BotChecker extends React.Component {

  /**
   *  コンストラクタ
   *  @version 2018/06/10
   */
  constructor(props) {

    super(props);

    let checks = [false, false, false, false, false];
    [...Array(2)].forEach(() => {
      checks[Math.floor(Math.random() * checks.length)] = true;
    });

    this.state = {
      checks: checks,
      complete: false,
    }
  }

  /**
   *  チェックする
   *  @version 2018/06/10
   */
  check = e => {

    let checks = this.state.checks.slice();
    checks[e.target.dataset.index] = true;
    this.setState({checks: checks}, () => {
      if (!this.state.checks.includes(false)) this.setState({complete: true});
    });
  }

  /**
   *  表示処理
   *  @version 2018/06/10
   */
  render() {

    return (
      <div className={Style.BotChecker}>
        { this.state.complete ?
          <div className='u-ta-center'>
            <div className={Style.BotChecker__complete}></div><span className='u-fs-m u-ml-10'>ロボットではありません</span>
            <input type='hidden' name='botcheck' value='1' />
          </div>
          :
          <div>
            大変お手数ですがロボットではないことを判定するため、全ての<span className='u-fc-blue u-fw-bold'>青色</span>を{this.props.is_sp ? 'タップ' : 'クリック'}してください。
            <div className={Style.BotChecker__checks}>
              { this.state.checks.map((check, i) => {
                const key = `check-${i}`;
                return <div {...{key}} className={`${Style.BotChecker__check} ${check ? Style.BotChecker__checked : null}`} data-index={i} onClick={e => this.check(e)}></div>;
              })}
            </div>
          </div>
        }
      </div>
    );
  }
}
