require('@rails/ujs').start()

// 初期化処理
import Initializer from 'lib/initializer'
import Particles   from 'front/particles'
import WebpackerReact from 'webpacker-react'
import BotChecker from 'sessions/bot_checker'
import DataModal from 'works/data_modal'

Initializer.loadWebfont();
Initializer.analytics();

// turbolinksでのリクエスト時に発動
document.addEventListener('turbolinks:load', () => {

  Initializer.lazy();
  Initializer.stickyStart();
  Initializer.navigation();
  Initializer.smoothScroll();
  Particles.start();

  // instagram埋め込みturbolink対策
  if (window.instgrm) window.instgrm.Embeds.process();
});

WebpackerReact.setup({
  DataModal,
  BotChecker,
});
