/**
 *  パーティクルクラス
 *  @version 2018/06/10
 */
export default new class Particles {

  constructor() {

    this.center = {};    // Canvas中央
    this.dots = [];      // パーティクル配列
    this.density = 0;  //パーティクルの数
  }

  update = () => {
    requestAnimFrame(this.update);
    // 描画をクリアー
    this.ctx.clearRect(0, 0, this.ctx.canvas.width, this.ctx.canvas.height);

    for (var i = 0; i < this.density; i++) {
      this.dots[i].update();
    }
  }

  /**
   *  開始
   *  @version 2018/06/10
   */
  start = () => {

    window.requestAnimFrame = (callback => {
      return window.requestAnimationFrame ||
        window.webkitRequestAnimationFrame ||
        window.mozRequestAnimationFrame ||
        window.oRequestAnimationFrame ||
        window.msRequestAnimationFrame ||
        callback();
    })();

    this.canvas = document.querySelector('#js-particles');

    if (!this.canvas) return false;

    this.ctx = this.canvas.getContext('2d');

    this.density = Math.floor(this.canvas.parentNode.clientHeight / 1000 + 1) * 10;

    // canvasにコンテンツサイズをセット
    this.canvas.setAttribute('width', this.canvas.parentNode.offsetWidth);
    this.canvas.setAttribute('height', this.canvas.parentNode.offsetHeight);

    // canvas中央をセット
    this.center.x = this.canvas.width / 2;
    this.center.y = this.canvas.height / 2;

    // densityの数だけパーティクルを生成
    for (var i = 0; i < this.density; i++) {
      this.dots.push(new Dot(this.canvas));
    }

    this.update();
  }
}

class Dot {

  /**
   *  コンストラクタ
   *  @version 2018/06/10
   */
  constructor(canvas) {

    this.canvas = canvas;
    this.ctx = this.canvas.getContext('2d');

    this.colors = ['#eeb900', '#6DD0A5', '#f799db'];

    this.type = Math.floor( Math.random() * 2 );
    this.size = Math.floor( Math.random() * 3 ) + 2; //大きさ
    this.color = this.colors[~~(Math.random() * 3)]; //色
    this.pos = {   // 位置
      x: Math.random() * this.canvas.width,
      y: Math.random() * this.canvas.height
    };
    var rot = Math.random() * 360;  // ランダムな角度
    var angle = rot * Math.PI / 180;
    this.vec = {    // 移動方向
      x: Math.cos(angle),
      y: Math.sin(angle)
    };
  }

  /**
   *  描画の更新
   *  @version 2018/06/10
   */
  update = () => {

    this.draw();

    this.pos.x += (this.vec.x * 0.2);
    this.pos.y += (this.vec.y * 0.2);

    // 画面外に出たら反対へ再配置
    if(this.pos.x > this.canvas.width + 1) {
      this.pos.x = -2;
    } else if(this.pos.x < 0 - 1) {
      this.pos.x = this.canvas.width + 2;
    } else if(this.pos.y > this.canvas.height + 1) {
      this.pos.y = -2;
    } else if(this.pos.y < 0 - 1) {
      this.pos.y = this.canvas.height + 2;
    }
  }

  /**
   *  パーティクルの移動
   *  @version 2018/06/10
   */
  draw = () => {

    this.ctx.fillStyle = this.color;
    this.ctx.beginPath();
    if (this.type == 0) {
      this.ctx.arc(this.pos.x, this.pos.y, this.size, 0, 2 * Math.PI, false);
    } else {
      this.ctx.rect(this.pos.x, this.pos.y, this.size * 1.8, this.size * 1.8);
    }
    this.ctx.fill();
  }
}
